<template>
  <div
    class="
      min-h-screen
      bg-gray-50
      flex flex-col
      justify-center
      py-12
      sm:px-6
      lg:px-8
    "
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <!-- <img
        class="mx-auto h-16 w-auto"
        src="https://v88.own.ag/images/logo.png"
        alt="Workflow"
      /> -->
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Sign in to your account
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600 max-w">
        Or
        <Link text="contact us to join our beta program!" />
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div v-for="error in errors" :key="error" class="mb-4">
          <ErrorAlert
            v-for="errorMessage in error"
            :key="errorMessage"
            :text="errorMessage"
          />
        </div>
        <form @submit="submit" class="space-y-6" action="#" method="POST">
          <LabeledInput
            :value="email"
            @updated="email = $event.target.value"
            type="email"
            label="Email Address"
          />
          <LabeledInput
            :value="password"
            @updated="password = $event.target.value"
            type="password"
            label="Password"
          />
          <div class="flex items-center justify-between">
            <Checkbox label="Remember me" />
            <div class="text-sm">
              <Link text="Forgot your password?" />
            </div>
          </div>
          <Button
            :disabled="loading"
            type="submit"
            :label="loading ? 'Signing in..' : 'Sign in'"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import LabeledInput from "@/components/LabeledInput.vue";
import Button from "@/components/Button.vue";
import Checkbox from "@/components/Checkbox.vue";
import Link from "@/components/Link.vue";
import ErrorAlert from "@/components/ErrorAlert.vue";

export default {
  components: {
    LabeledInput,
    Button,
    Checkbox,
    Link,
    ErrorAlert,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["errors", "loading"]),
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      const success = await this.login({
        email: this.email,
        password: this.password,
      });
      if (success) {
        location.reload();
      }
    },
    ...mapActions("auth", ["login"]),
  },
};
</script>
