<template>
  <a :href="to" class="font-medium text-yellow-500 hover:text-yellow-400">
    {{ text }}
  </a>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "#",
    },
  },
};
</script>

<style></style>
