<template>
  <button
    :type="type"
    class="
      w-full
      flex
      justify-center
      py-2
      px-4
      border border-transparent
      rounded-md
      shadow-sm
      text-sm
      font-medium
      text-white
      bg-blue-500
      hover:bg-blue-600
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
    "
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "button",
    },
  },
};
</script>
